<template>
    <Breadcrumbs
        main="Liste des notifications"
        mains="Liste des notifications"
        title="Publication"
    />
    <div class="container-fluid">
        <div class="row">
            <NotificationList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const NotificationList = defineAsyncComponent(
    () => import('@/components/theme/notification/NotificationList.vue'),
);
</script>