import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-6 col-12" }
const _hoisted_5 = { class: "col-sm-6 col-12" }
const _hoisted_6 = { class: "breadcrumb" }
const _hoisted_7 = { class: "breadcrumb-item" }
const _hoisted_8 = { class: "breadcrumb-item" }
const _hoisted_9 = { class: "breadcrumb-item active" }



export default /*@__PURE__*/_defineComponent({
  __name: 'BreadCrumbs',
  props: {
    title: {
        default: 'home'
    },
    main: {
        default: ''
    },
    mains: {
        default: ''
    },

},
  setup(__props) {

let props = __props

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(__props.mains), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ol", _hoisted_6, [
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("i", { class: "iconly-Home icli svg-color" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, _toDisplayString(__props.main), 1),
            _createElementVNode("li", _hoisted_9, _toDisplayString(__props.title), 1)
          ])
        ])
      ])
    ])
  ]))
}
}

})