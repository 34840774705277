<template>
    <Breadcrumbs main="Ville" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <SurveyResults />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const SurveyResults = defineAsyncComponent(
    () => import('@/components/theme/survey/SurveyResults.vue'),
);
</script>