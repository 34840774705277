import { authHeader } from './auth-header';
import Config from '@/config';

class AuthService {
    async login(user: any) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/auth/sign_in';
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                }),
                body: JSON.stringify(user),
            })
                .then((data) => {
                    data.json()
                        .then((jsonData) => {
                            if (data.ok) {
                                resolve(jsonData);
                            } else reject(jsonData);
                        })
                        .catch((e) =>
                            reject(new Error('error.dataNotProvided')),
                        );
                })
                .catch((e) => reject(new Error('error.dataNotProvided')));
        });
    }

    register(user: any) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/auth/sign_up';
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                }),
                body: JSON.stringify(user),
            })
                .then((data) => {
                    data.json().then((jsonData) => {
                        if (data.ok) {
                            resolve(jsonData);
                        } else {
                            reject(new Error(jsonData.message));
                        }
                    });
                })
                .catch((e) => reject(new Error('error.exception')));
        });
    }

    async loginWithFirebase(user: any) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/auth/firebase/sign_in';
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                }),
                body: JSON.stringify(user),
            })
                .then((data) => {
                    data.json()
                        .then((jsonData) => {
                            if (data.ok) {
                                resolve(jsonData);
                            } else reject(jsonData);
                        })
                        .catch((e) =>
                            reject(new Error('error.dataNotProvided')),
                        );
                })
                .catch((e) => reject(new Error('error.dataNotProvided')));
        });
    }

    forgotPassword(email: string) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/users/sendPasswordMail/';
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                }),
                body: JSON.stringify(email),
            })
                .then((data) => {
                    data.json()
                        .then((jsonData) => {
                            if (data.ok) {
                                resolve(jsonData);
                            } else if (data.status == 401) {
                                reject(new Error('error.Unauthorized'));
                            } else {
                                reject(new Error(jsonData.message));
                            }
                            //else reject(new Error('error.dataNotProvided'))
                        })
                        .catch((e) => reject(new Error(e.message)));
                })
                .catch((e) => reject(new Error('error.internal')));
        });
    }

    checkToken(token: string) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/auth/check';
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: token,
                }),
                body: undefined,
            })
                .then((data) => {
                    if (data.ok) {
                        resolve(true);
                    } else {
                        reject(
                            new Error(
                                'Unauthorized access: You need have authorization token',
                            ),
                        );
                    }
                })
                .catch((e) => reject(new Error('error.internal')));
        });
    }
}

export default new AuthService();
