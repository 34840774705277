import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-header row" }
const _hoisted_2 = { class: "page-body-wrapper" }
const _hoisted_3 = { class: "page-sidebar" }
const _hoisted_4 = { class: "page-body" }

import { defineAsyncComponent, ref, onMounted, watch } from 'vue';
import { useMenuStore } from '@/store/menu';
import { uselayoutStore } from '@/store/layout';
import { storeToRefs } from 'pinia';

export default /*@__PURE__*/_defineComponent({
  __name: 'BodyView',
  setup(__props) {

const TapTop = defineAsyncComponent(() => import('@/layouts/TapTop.vue'));
const FooterView = defineAsyncComponent(
    () => import('@/components/common/block/FooterView.vue'),
);
const Sidebar = defineAsyncComponent(
    () => import('@/components/common/block/sidebar/IndexSidebar.vue'),
);
const Header = defineAsyncComponent(
    () => import('@/components/common/block/header/IndexHeader.vue'),
);
const Customizer = defineAsyncComponent(
    () => import('@/components/common/block/customizer/IndexCustomizer.vue'),
);

let sidebar_toggle_var = ref<boolean>(false);
let display = ref<boolean>(false);
let layoutobj = ref({});
let storeLayout = uselayoutStore();
const store = useMenuStore();
const { layouts: layouts } = storeToRefs(storeLayout);
watch(
    () => layouts,
    () => {
        layoutobj.value = storeLayout.layouts.settings.sidebar_setting;
    },
    { deep: true },
);

function sidebar_toggle(value: boolean) {
    sidebar_toggle_var.value = !value;
}
function handleScroll() {
    if (window.innerWidth <= 1199) {
        display.value = true;
        store.togglesidebar = false;
    } else {
        store.togglesidebar = true;
        display.value = false;
    }
}
onMounted(() => {
    window.addEventListener('resize', handleScroll);
    layoutobj.value = storeLayout.layouts.settings.sidebar_setting;
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(TapTop)),
    _createElementVNode("div", {
      class: _normalizeClass(["page-wrapper", [
            !_unref(store).togglesidebar ? 'sidebar-open' : '',
            _unref(display) ? 'compact-wrapper ' : _unref(layoutobj),
        ]]),
      id: "pageWrapper"
    }, [
      _createElementVNode("header", _hoisted_1, [
        _createVNode(_unref(Header))
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overlay" }, null, -1)),
        _createElementVNode("aside", _hoisted_3, [
          _createVNode(_unref(Sidebar))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_unref(FooterView))
      ])
    ], 2),
    _createVNode(_unref(Customizer))
  ], 64))
}
}

})