import apiServices from './apiServices';

class CategoryServices {
    async getCategories() {
        let availableCategories = [];
        let fields = ['title', 'parentCategoryId', 'status'];
        await apiServices
            .getAll('pageCategories', {}, fields, null)
            .then((response: any) => {
                if (response.entries) {
                    response.entries.forEach((element: any) => {
                        // root elemnt
                        let cat = {
                            id: element.id,
                            label: element.title,
                            status: element.status,
                            children: [],
                        };
                        if (element.parentCategoryId === '') {
                            //rank 2
                            response.entries.forEach((subelement: any) => {
                                if (subelement.parentCategoryId === cat.id) {
                                    let subcat = {
                                        id: subelement.id,
                                        label: subelement.title,
                                        status: subelement.status,
                                        parentCategoryId: cat.id,
                                        children: [],
                                    };
                                    cat.children.push(subcat);
                                    //rank 3
                                    response.entries.forEach(
                                        (ssubelement: any) => {
                                            if (
                                                ssubelement.parentCategoryId ===
                                                subcat.id
                                            ) {
                                                let ssubcat = {
                                                    id: ssubelement.id,
                                                    label: ssubelement.title,
                                                    status: ssubelement.status,
                                                    parentCategoryId: subcat.id,
                                                    children: [],
                                                };
                                                subcat.children.push(ssubcat);
                                            }
                                        },
                                    );
                                }
                            });
                            availableCategories.push(cat);
                        }
                    });
                }
            });
        return availableCategories;
    }
}
export default new CategoryServices();
