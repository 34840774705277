<template>
    <Breadcrumbs main="Signalement" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <ReportView :report="report" :pictures="pictures" />
        <div class="row">
            <div class="col">
                <div class="text-end">
                    <button
                        class="btn dropdown-toggle btn-primary text-white me-1"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Changer l'état
                    </button>
                    <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <li>
                            <a
                                class="dropdown-item"
                                @click="setStatus('TRANSMITED')"
                                ><i class="fa fa-paper-plane"></i>
                                {{ $t('TRANSMITED') }}</a
                            >
                        </li>
                        <li>
                            <a
                                class="dropdown-item"
                                @click="setStatus('PLANIFIED')"
                                ><i class="fa fa-eye"></i>
                                {{ $t('PLANIFIED') }}</a
                            >
                        </li>
                        <li>
                            <a
                                class="dropdown-item"
                                @click="setStatus('INPROGRESS')"
                                ><i class="fa fa-gear"></i>
                                {{ $t('INPROGRESS') }}</a
                            >
                        </li>
                        <li>
                            <a
                                class="dropdown-item text-success"
                                @click="setStatus('FINISHED')"
                                ><i class="fa fa-flag"></i>
                                {{ $t('FINISHED') }}</a
                            >
                        </li>
                        <li><hr class="dropdown-divider" /></li>
                        <li>
                            <a
                                class="dropdown-item text-warning"
                                @click="setStatus('PENDING')"
                                ><i class="fa fa-pause"></i>
                                {{ $t('PENDING') }}</a
                            >
                        </li>
                        <li>
                            <a
                                class="dropdown-item text-danger"
                                @click="setStatus('DELETED')"
                                ><i class="fa fa-trash"></i>
                                {{ $t('DELETED') }}</a
                            >
                        </li>
                    </ul>
                    <a
                        class="btn btn-info"
                        target="_blank"
                        @click="generateReport()"
                        >{{ $t('pageReportEdit.pdf') }}</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { report } from '@/core/data/report';

const pictures = ref([]);
const ReportView = defineAsyncComponent(
    () => import('@/components/theme/report/ReportView.vue'),
);

let report = ref<report>(null);
const title = computed(() => {
    return report.value && report.value.id
        ? 'Traitement du signalement : ' + report.value.id
        : '';
});
const setStatus = async (status: string) => {
    useLoaderState().changeStateTrue();
    ApiServices.setStatus('reports', status, report.value.id, null)
        .then((response: any) => {
            success_message('signalement modifié.');
            getDatas();
        })
        .catch((ex) => {
            error_message(ex);
        })
        .finally(() => {
            useLoaderState().changeStateFalse();
        });
};
const generateReport = async () => {
    try {
        success_message('Rapport généré.');
        await ApiServices.generatePdf(
            'reports/pdf',
            report.value.id,
            'Rapport_',
        );
    } catch (ex) {
        error_message(ex);
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('reports', route.query.id.toString()).then(
            async (response: any) => {
                report.value = response;
                if (report.value.pictures && report.value.pictures.length > 0) {
                    pictures.value = [];
                    for (let i = 0; i < report.value.pictures.length; i++) {
                        await ApiServices.getById(
                            'GCS',
                            report.value.pictures[i],
                        ).then((response: any) => {
                            pictures.value.push(response);
                        });
                    }
                }
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>