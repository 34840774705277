<template>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card login-dark">
                    <div>
                        <div class="login-main">
                            <form class="theme-form" @submit.prevent="login">
                                <h2 class="text-center">{{ $t('login.title') }}</h2>
                                <p class="text-center">{{ $t('login.subTitle') }}</p>
                                <div v-if="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">{{ $t('login.login') }}</label>
                                    <input class="form-control" v-model="email" type="email" required placeholder="test@gmail.com">
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">{{ $t('login.password') }}</label>
                                    <div class="form-input position-relative">
                                        <input class="form-control" :type="type" v-model="password" name="login[password]" required
                                            placeholder="*********">
                                        <div class="show-hide"><span :class="type == 'password' ? 'show' : ''"
                                                @click="showPassword"> </span></div>
                                    </div>
                                </div>
                                <div class="form-group mb-0 checkbox-checked">
                                    <div class="form-check checkbox-solid-info"><!-- 
                                        <input class="form-check-input" id="solid6" type="checkbox">
                                        <label class="form-check-label" for="solid6">Remember password</label> -->
                                    </div><router-link class="link" to="/forget_password">{{ $t('login.forgotPassword') }}</router-link>
                                    <div class="text-end mt-3">
                                        <button class="btn btn-primary btn-block w-100" type="submit">{{ $t('user.login-button') }}</button>
                                    </div>
                                </div>
                                <div class="login-social-title">
                                    <h6>{{ $t('login.orSign') }}</h6>
                                </div>
                                <div class="form-group">
                                    <ul class="login-social">
                                        <li><a href="https://www.linkedin.com" target="_blank"><i
                                                    class="icon-linkedin"></i></a></li>
                                        <li><a href="https://twitter.com" target="_blank"><i class="icon-twitter"></i></a>
                                        </li>
                                        <li><a href="https://www.facebook.com" target="_blank"><i
                                                    class="icon-facebook"></i></a></li>
                                        <li><a href="https://www.instagram.com" target="_blank"><i
                                                    class="icon-instagram"></i></a></li>
                                    </ul>
                                </div>
                                <p class="mt-4 mb-0 text-center">{{ $t('login.notRegister') }}<router-link class="ms-2"
                                        to="/register">{{ $t('login.register') }}</router-link></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
const email = ref<string>('')
const password = ref<string>('')
let message = ref(null)
const type = ref<string>('password')
import { useAuthStore } from '@/store/auth'
function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

function login() {
    try {
        const authStore = useAuthStore()
        authStore.login({email:email.value, password:password.value}).catch((error) => (message.value = error.message))
    } finally {
    }
}
</script>