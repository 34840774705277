<template>
    <Breadcrumbs
        main="Banque d'images"
        mains="Banque d'images"
        title="Banque d'images"
    />
    <div class="container-fluid">
        <div class="row">
            <GridDesc />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const GridDesc = defineAsyncComponent(
    () => import('@/components/theme/gallery/GridDescription.vue'),
);
</script>