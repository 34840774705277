<template>
    <Breadcrumbs main="Categories" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <NotificatioTopicEdit :notificationTopic="notificationTopic" />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { notificationTopic } from '@/core/data/notificationTopic';
const NotificatioTopicEdit = defineAsyncComponent(
    () => import('@/components/theme/notification/NotificationTopicEdit.vue'),
);
let notificationTopic = ref<notificationTopic>({
    id: '',
    title: '',
    key: '',
    status: 'CREATED',
    createdBy: undefined,
    createdAt: new Date(),
    updatedBy: undefined,
    updatedAt: new Date(),
});

const title = computed(() => {
    return notificationTopic.value && notificationTopic.value.id
        ? "Modification d'un sujet de notification"
        : "Création d'un sujet de notification";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.email !== undefined) {
        //@ts-ignore
        ApiServices.update(
            'notificationTypes',
            notificationTopic.value,
            notificationTopic.value.id,
        )
            .then(() => {
                success_message('Sujet de notification modifié.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('notificationTypes', notificationTopic.value)
            .then(() => {
                success_message('Sujet de notification créé.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById(
            'notificationTypes',
            route.query.id.toString(),
        ).then((response: any) => {
            notificationTopic.value = response;
        });
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>