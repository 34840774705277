/* eslint-disable */
import { authHeader } from './auth-header';
import router from './../router/index';
import Config from '@/config';
import ApiQuery from './apiQuery';

class ApiServices {
    async resolveApiResponse(
        data: Response,
        default_data: { entries?: undefined[] },
        resolve: any,
        reject: any,
    ) {
        if (data.ok) {
            if (data.status === 204) {
                resolve({
                    statusCode: '10005',
                    message: 'no data',
                    data: default_data,
                });
            } else
                data.json()
                    .then((jsonData: any) => {
                        resolve(jsonData);
                    })
                    .catch((error: string | undefined) =>
                        reject(new Error(error)),
                    );
        } else {
            if (data.status === 404) {
                resolve({
                    statusCode: '10005',
                    message: 'no data',
                    data: default_data,
                });
            } else if (data.status == 400) {
                router.push('/error-400');
            } else if (data.status == 403) {
                router.push('/login');
            } else {
                data.json().then((jsonData: any) => {
                    reject(jsonData);
                });
            }
        }
    }
    async getAll(
        objectName: string,
        filters: any,
        fields: Array<string>,
        pagination: any,
    ) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/' + objectName;
            url = ApiQuery.parseQueryParam(filters, url);
            if (pagination !== null)
                url = ApiQuery.parsePagination(pagination, url);
            url = ApiQuery.parseFields(fields, url);
            fetch(url, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((data) => {
                    this.resolveApiResponse(
                        data,
                        { entries: [] },
                        resolve,
                        reject,
                    );
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async get(objectName: string, filters: any) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/' + objectName;
            url = ApiQuery.parseQueryParam(filters, url);
            fetch(url, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async getById(objectName: string, id: string) {
        return new Promise((resolve, reject) => {
            fetch(Config.api.backendUrl + '/api/v1/' + objectName + '/' + id, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async create(objectName: string, requestBody: any) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/' + objectName;
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
                body: JSON.stringify(requestBody),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async update(objectName: string, requestBody: any, id: string) {
        return new Promise((resolve, reject) => {
            fetch(Config.api.backendUrl + '/api/v1/' + objectName + '/' + id, {
                method: 'PUT',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
                body: JSON.stringify(requestBody),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async count(objectName: string, filters: object) {
        return new Promise((resolve, reject) => {
            let url =
                Config.api.backendUrl + '/api/v1/' + objectName + '/count';

            url = ApiQuery.parseQueryParam(filters, url);
            fetch(url, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async delete(objectName: string, id: string) {
        return new Promise((resolve, reject) => {
            fetch(Config.api.backendUrl + '/api/v1/' + objectName + '/' + id, {
                method: 'DELETE',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async setStatus(
        objectName: string,
        status: string,
        id: string,
        filter: any,
    ) {
        let s = { status: status };
        let url =
            Config.api.backendUrl +
            '/api/v1/' +
            objectName +
            '/setStatus/' +
            id;
        if (filter !== null) url = url + filter;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PUT',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
                body: JSON.stringify(s),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async upload(objectName: string, file: any) {
        const formData = new FormData();
        for (const name in file) {
            formData.append(name, file[name]);
        }
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/' + objectName;
            fetch(url, {
                method: 'POST',
                redirect: 'follow',
                headers: new Headers({
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
                body: formData,
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async download(url: string, fileName: any) {
        return new Promise((resolve, reject) => {
            fetch(Config.api.backendUrl + '/api/v1/' + url, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'text/html',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((response) => {
                    response
                        .blob()
                        .then((datas) => {
                            var fileURL = window.URL.createObjectURL(
                                new Blob([datas]),
                            );
                            var fURL = document.createElement('a');
                            fURL.href = fileURL;
                            fURL.setAttribute('download', fileName);
                            document.body.appendChild(fURL);
                            fURL.click();
                            resolve(true);
                        })
                        .catch((error) => reject(new Error(error)));
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async generatePdf(url: string, id: string, fileName: string = 'file') {
        return new Promise((resolve, reject) => {
            fetch(Config.api.backendUrl + '/api/v1/' + url + '/' + id, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'text/html',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((resp) => resp.arrayBuffer())
                .then((resp) => {
                    // set the blog type to final pdf
                    const file = new Blob([resp], { type: 'application/pdf' });

                    // process to auto download it
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = fileName + id + '.pdf';
                    link.click();
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
    async getBetweenDates(
        objectName: string,
        filters: any,
        startDate: string,
        endDate: string,
    ) {
        return new Promise((resolve, reject) => {
            let url = Config.api.backendUrl + '/api/v1/' + objectName;
            if (
                startDate !== null &&
                startDate !== undefined &&
                endDate !== null &&
                endDate !== undefined
            ) {
                url = url + '?startDate=' + startDate + '&endDate=' + endDate;
            }
            url = ApiQuery.parseQueryParam(filters, url);
            fetch(url, {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': Config.api.BackendApiKey,
                    authorization: authHeader(),
                }),
            })
                .then((data) => {
                    this.resolveApiResponse(data, {}, resolve, reject);
                })
                .catch((e) => reject(new Error(e.message)));
        });
    }
}

export default new ApiServices();
