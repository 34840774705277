import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-wrapper"
}

import { useRouter } from 'vue-router';
import { useLoaderState } from '@/store/loading';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isLoading = useLoaderState();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isLoading).state)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "loader" }, [
            _createElementVNode("span"),
            _createElementVNode("span"),
            _createElementVNode("span"),
            _createElementVNode("span"),
            _createElementVNode("span")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}
}

})