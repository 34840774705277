import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "edit-profile" }
const _hoisted_3 = { class: "row" }

import { ref, defineAsyncComponent, onMounted } from 'vue';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import { error_message, success_message } from '@/components/common/toast';

export default /*@__PURE__*/_defineComponent({
  __name: 'AccountEdit',
  setup(__props) {

const EditProfile = defineAsyncComponent(
    () => import('@/components/theme/account/EditProfile.vue'),
);
const ChangePassword = defineAsyncComponent(
    () => import('@/components/theme/account/ChangePassword.vue'),
);
const me = ref({});

const updateMe = async () => {
    useLoaderState().changeStateTrue();
    //@ts-ignore
    ApiServices.update('users', me.value, me.value._id)
        .then(() => {
            success_message('Profil modifié');
        })
        .catch((ex) => {
            error_message(ex);
        })
        .finally(() => {
            useLoaderState().changeStateFalse();
        });
};
const getDatas = async () => {
    ApiServices.get('users/me', {}).then((response: any) => {
        me.value = response;
    });
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Mon compte",
      mains: "Mon compte",
      title: "Profil"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(EditProfile), {
            me: me.value,
            onUpdate: updateMe
          }, null, 8, ["me"]),
          _createVNode(_unref(ChangePassword))
        ])
      ])
    ])
  ], 64))
}
}

})