export default (
    to: any,
    from: any,
    next: (arg0: string | undefined) => void,
) => {
    if (
        to.matched.some(
            (record: { meta: { loginRequired: any } }) =>
                record.meta.loginRequired,
        )
    ) {
        const user = JSON.parse(localStorage.getItem('user')!);
        if (
            user !== null &&
            user.token !== null &&
            user.user !== undefined &&
            user.user.role !== null
        ) {
            const roleArrayHierarchic = to.matched
                .filter((x: { meta: { roles: any } }) => x.meta.roles)
                .map((x: { meta: { roles: any } }) => x.meta.roles);
            if (
                roleArrayHierarchic.every((x: string | any[]) =>
                    x.includes(user.user.role),
                )
            ) {
                next(undefined);
            } else {
                next('/error-403');
            }
        } else {
            next('/login');
        }
    } else {
        next(undefined);
    }
};
