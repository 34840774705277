import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'bootstrap';
import './assets/scss/app.scss';

import VueFeather from 'vue-feather';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import { createI18n } from 'vue-i18n';
import Breadcrumbs from './layouts/BreadCrumbs.vue';
import Lightbox from 'vue-easy-lightbox';
import Datepicker from '@vuepic/vue-datepicker';
import TreeSelect from '@r2rka/vue3-treeselect';
//cards
import Card3 from '@/components/common/card/CardData3.vue';
import DataTableFilters from '@/components/common/DataTableFilters.vue';
//charts
import VueApexCharts from 'vue3-apexcharts';
// vue input mask
import { vMaska } from 'maska/vue';

import English from '@/core/locales/en.json';
import Français from '@/core/locales/fr.json';
const i18n = createI18n({
    legacy: false,
    locale: 'Français',
    messages: {
        English: English,
        Français: Français,
    },
});

//dayjs in french
// @ts-ignore
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr');

createApp(App)
    .use(router)
    .use(createPinia())
    .use(i18n)
    .use(PerfectScrollbar)
    .use(VueApexCharts)
    //mask
    .directive('maska', vMaska)
    // @ts-ignore
    .use(dayjs)
    .use(Lightbox)
    .component('Breadcrumbs', Breadcrumbs)
    .component(VueFeather.name!, VueFeather)
    .component('Datepicker', Datepicker)
    .component('TreeSelect', TreeSelect)
    .component('Card3', Card3)
    .component('DataTableFilters', DataTableFilters)
    .mount('#app');
