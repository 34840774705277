<template>
    <div class="loader-wrapper" v-if="isLoading.state">
        <div class="loader">
            <span></span><span></span><span></span><span></span><span></span>
        </div>
    </div>
    <router-view />
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { useLoaderState } from '@/store/loading';
const isLoading = useLoaderState();
</script>
<style lang="scss"></style>
