<template>
    <Breadcrumbs
        main="Liste des villes"
        mains="Liste des villes"
        title="Paramètres"
    />
    <div class="container-fluid">
        <div class="row">
            <TownList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';
const TownList = defineAsyncComponent(
    () => import('@/components/theme/town/TownList.vue'),
);
</script>