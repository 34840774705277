<template>
    <Breadcrumbs main="Mon compte" mains="Mon compte" title="Profil" />
    <div class="container-fluid">
        <div class="edit-profile">
            <div class="row">
                <EditProfile :me="me" @update="updateMe" />
                <ChangePassword></ChangePassword>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineAsyncComponent, onMounted } from 'vue';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import { error_message, success_message } from '@/components/common/toast';
const EditProfile = defineAsyncComponent(
    () => import('@/components/theme/account/EditProfile.vue'),
);
const ChangePassword = defineAsyncComponent(
    () => import('@/components/theme/account/ChangePassword.vue'),
);
const me = ref({});

const updateMe = async () => {
    useLoaderState().changeStateTrue();
    //@ts-ignore
    ApiServices.update('users', me.value, me.value._id)
        .then(() => {
            success_message('Profil modifié');
        })
        .catch((ex) => {
            error_message(ex);
        })
        .finally(() => {
            useLoaderState().changeStateFalse();
        });
};
const getDatas = async () => {
    ApiServices.get('users/me', {}).then((response: any) => {
        me.value = response;
    });
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>