<template>
    <Breadcrumbs
        main="Carte interactive"
        mains="Carte interactive"
        title="Général"
    />
    <div class="container-fluid">
        <div class="row">
            <InteractiveMap />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';
const InteractiveMap = defineAsyncComponent(
    () => import('@/components/theme/map/InteractiveMap.vue'),
);
</script>