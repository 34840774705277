import { defineStore } from 'pinia';
import router from './../router/index';
import AuthService from '@/services/authServices';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user')!)
            : null,
    }),
    actions: {
        async login(user: { email: string; password: string }) {
            return AuthService.login(user).then(
                (user: any) => {
                    // update pinia state
                    this.user = user;
                    // store user details and jwt in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    // redirect to previous url or default to home page
                    router.push('/');
                },
                (error: any) => {
                    return Promise.reject(error);
                },
            );
        },
        async register(user: {
            firstName: string;
            lastName: string;
            email: string;
            password: string;
        }) {
            return AuthService.register(user).then(
                (user: any) => {
                    // redirect to previous url or default to home page
                    router.push('/');
                },
                (error: any) => {
                    return Promise.reject(error);
                },
            );
        },
        async sendPasswordEmail(email: string) {
            return AuthService.forgotPassword(email).then(
                (user: any) => {
                    // redirect to previous url or default to home page
                    router.push('/');
                },
                (error: any) => {
                    return Promise.reject(error);
                },
            );
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            router.push('/login');
        },
    },
});
