<template>
    <Breadcrumbs main="Catégorie de page" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <SocialNetworkEdit :socialNetwork="socialNetwork" />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { socialNetwork } from '@/core/data/socialNetwork';
const SocialNetworkEdit = defineAsyncComponent(
    () => import('@/components/theme/socialnetwork/SocialNetworkEdit.vue'),
);
let socialNetwork = ref<socialNetwork>({
    id: '',
    title: '',
    type: 'FACEBOOK',
    url: '',
    imageUrl: undefined,
    createdAt: new Date(),
    createdBy: undefined,
    updatedAt: new Date(),
    updatedBy: undefined,
});

const title = computed(() => {
    return socialNetwork.value && socialNetwork.value.id
        ? "Modification d'un réseau social"
        : "Création d'un réseau social";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.id !== undefined) {
        //@ts-ignore
        ApiServices.update(
            'socialNetworks',
            socialNetwork.value,
            socialNetwork.value.id,
        )
            .then(() => {
                success_message('Réseau social modifié.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('socialNetworks', socialNetwork.value)
            .then(() => {
                success_message('Réseau social crée.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('socialNetworks', route.query.id.toString()).then(
            (response: any) => {
                socialNetwork.value = response;
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>