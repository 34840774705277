<template>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-12 p-0">
                <div class="login-card login-dark">
                    <div>
                        <div class="login-main">
                            <form class="theme-form" @submit.prevent="register">
                                <h2 class="text-center">
                                    {{ $t('register.title') }}
                                </h2>
                                <p class="text-center">
                                    {{ $t('register.please-register') }}
                                </p>
                                <div
                                    v-if="message"
                                    class="alert alert-danger"
                                    role="alert"
                                >
                                    {{ message }}
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label pt-0">{{
                                        $t('register.names')
                                    }}</label>
                                    <div class="row g-2">
                                        <div class="col-6">
                                            <input
                                                class="form-control"
                                                type="text"
                                                v-model="firstName"
                                                required
                                                :placeholder="
                                                    $t('register.firstName')
                                                "
                                            />
                                        </div>
                                        <div class="col-6">
                                            <input
                                                class="form-control"
                                                type="text"
                                                v-model="lastName"
                                                required
                                                :placeholder="
                                                    $t('register.lastName')
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">{{
                                        $t('register.email')
                                    }}</label>
                                    <input
                                        class="form-control"
                                        type="email"
                                        v-model="email"
                                        required
                                        placeholder="Test@gmail.com"
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">{{
                                        $t('register.password')
                                    }}</label>
                                    <div class="form-input position-relative">
                                        <input
                                            class="form-control"
                                            :type="type"
                                            name="login[password]"
                                            required
                                            v-model="password"
                                            placeholder="*********"
                                        />
                                        <div class="show-hide">
                                            <span
                                                :class="
                                                    type == 'password'
                                                        ? 'show'
                                                        : ''
                                                "
                                                @click="showPassword"
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-0 checkbox-checked">
                                    <div class="form-check checkbox-solid-info">
                                        <input
                                            class="form-check-input"
                                            id="solid6"
                                            type="checkbox"
                                            v-model="accept"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="solid6"
                                            >{{ $t('register.agree') }}</label
                                        ><router-link
                                            class="ms-3 link"
                                            to="/forget_password"
                                            >conditions & la politique de
                                            confidentialité</router-link
                                        >
                                    </div>
                                    <button
                                        class="btn btn-primary btn-block w-100 mt-3"
                                        type="submit"
                                    >
                                        Créer mon compte
                                    </button>
                                </div>
                                <div class="login-social-title">
                                    <h6>{{ $t('login.orSign') }}</h6>
                                </div>
                                <div class="form-group">
                                    <ul class="login-social">
                                        <li>
                                            <a
                                                href="https://www.linkedin.com"
                                                target="_blank"
                                                ><i class="icon-linkedin"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://twitter.com"
                                                target="_blank"
                                                ><i class="icon-twitter"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.facebook.com"
                                                target="_blank"
                                                ><i class="icon-facebook"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com"
                                                target="_blank"
                                                ><i class="icon-instagram"></i
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                                <p class="mt-4 mb-0 text-center">
                                    {{ $t('register.please-please-login')
                                    }}<router-link class="ms-2" to="/login">{{
                                        $t('register.login')
                                    }}</router-link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
const type = ref<string>('password');
import { success_message, error_message } from '@/components/common/toast';
import { useAuthStore } from '@/store/auth';
const firstName = ref<string>('');
const lastName = ref<string>('');
const email = ref<string>('');
const password = ref<string>('');
const accept = ref<boolean>(false);
let message = ref(null);
function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}
function register() {
    try {
        if (accept.value === true) {
            const authStore = useAuthStore();
            authStore
                .register({
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    password: password.value,
                })
                .catch((error) => (message.value = error.message));
            success_message('Inscription réussie.');
        } else {
            message.value =
                'Vous devez accepter les conditions et la politique de confidentialité.';
        }
    } catch (ex) {
        message.value = ex.message;
        error_message(ex.message);
    } finally {
    }
}
</script>