<template>
    <Breadcrumbs
        main="Liste des pages"
        mains="Liste des pages"
        title="Publication"
    />
    <div class="container-fluid">
        <div class="row">
            <PageList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';
const PageList = defineAsyncComponent(
    () => import('@/components/theme/page/PageList.vue'),
);
</script>