export default {
    /**
     * convert Object to format query param string URL
     */
    parseQueryParam: (filters: any, url: string) => {
        let queryParams = url;
        if (filters !== undefined) {
            let queryOperator = '?';
            if (url.lastIndexOf('?') >= 0) {
                queryOperator = '&';
            }
            Object.keys(filters).forEach((filter) => {
                if (filters[filter] !== undefined || filters[filter] !== '') {
                    queryParams +=
                        queryOperator + filter + '=' + filters[filter];
                    queryOperator = '&';
                }
            });
        }
        return queryParams;
    },
    parsePagination: (pagination: any, url: string) => {
        let queryParams = url;
        if (pagination !== undefined) {
            let queryOperator = '?';
            if (url.lastIndexOf('?') >= 0) {
                queryOperator = '&';
            }
            // Pagination determination
            if (pagination.page && pagination.rowsPerPage) {
                let start = (pagination.page - 1) * pagination.rowsPerPage;
                let end = pagination.page * pagination.rowsPerPage - 1;
                queryParams += queryOperator + 'range=' + start + '-' + end;
            }
            // Sort
            if (queryParams.lastIndexOf('?') >= 0) {
                queryOperator = '&';
            }
            if (pagination.descending) {
                queryParams +=
                    queryOperator + 'sort=' + pagination.sortBy + '_desc';
            } else {
                queryParams +=
                    queryOperator + 'sort=' + pagination.sortBy + '_asc';
            }
        }
        return queryParams;
    },

    parseFields: (fields: Array<string>, url: string) => {
        let fieldsParams = url;
        if (fields !== undefined) {
            let queryOperator = '?';
            if (url.lastIndexOf('?') >= 0) {
                queryOperator = '&';
            }
            fieldsParams += queryOperator + 'fields=' + fields.toString();
        }
        return fieldsParams;
    },
    returnError: (data: { json: () => Promise<any> }) => {
        try {
            data.json().then((jsonData) => {
                if (jsonData !== undefined && jsonData.description) {
                    return new Error(jsonData.description);
                } else {
                    return new Error('error.dataNotProvided');
                }
            });
        } catch (err) {
            return new Error('error.dataNotProvided');
        }
        return new Error('error.dataNotProvided2');
    },
};
