import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "text-end" }

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import config from '@/config';
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { user } from '@/core/data/user';

export default /*@__PURE__*/_defineComponent({
  __name: 'UserEdit',
  setup(__props) {

const { tm, rt } = useI18n();
let route = useRoute();
const UserEdit = defineAsyncComponent(
    () => import('@/components/theme/user/UserEdit.vue'),
);
let towns = ref([]);
let user = ref<user>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: config.role.user,
    townId: '',
    avatar: undefined,
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    status: '',
});

const title = computed(() => {
    return user.value && user.value.email
        ? rt(tm('userEdit.updatetitle'))
        : rt(tm('userEdit.addtitle'));
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.email !== undefined) {
        //@ts-ignore
        ApiServices.update('users', user.value, user.value._id)
            .then(() => {
                success_message('Utilisateur modifié.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('users', user.value)
            .then(() => {
                success_message('Utilisateur créé.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.email !== undefined) {
        ApiServices.getById('users', route.query.email.toString()).then(
            (response: any) => {
                user.value = response;
            },
        );
    }
    ApiServices.getAll('town', { status_eq: 'ACTIVE' }, ['name'], null).then(
        (response: any) => {
            towns.value = response.entries;
        },
    );
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card3 = _resolveComponent("Card3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Utilisateur",
      mains: title.value,
      title: "Accueil"
    }, null, 8, ["mains"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card3, { colClass: "col-sm-12" }, {
          default: _withCtx(() => [
            _createVNode(_unref(UserEdit), { user: _unref(user) }, null, 8, ["user"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("a", {
                    class: "btn btn-success me-1",
                    target: "_blank",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (save()))
                  }, _toDisplayString(_ctx.$t('save')), 1)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})