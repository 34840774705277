<template>
    <div class="list-datatable-header">
        <div>
            <label class="col-xs-3 col-sm-auto col-form-label f-w-500"
                >{{ totalItems }} {{ $t('common.headerTable.items') }}</label
            >
            <label
                for="table-complete-search"
                class="col-xs-3 col-sm-auto col-form-label"
                >{{ $t('searchBy') }}:</label
            >
            <div class="col-xs-6 col-sm-auto">
                <select
                    class="form-select"
                    id="edgesselect"
                    v-model="selectedOption"
                >
                    <option
                        v-for="option in searchOptions"
                        :key="option.key"
                        :value="option.key"
                        @click="changeSearch(option)"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </div>
            <div class="col-xs-6 col-sm-auto">
                <div class="input-group">
                    <span class="input-group-text"
                        ><vue-feather
                            class="search-icon text-gray"
                            type="search"
                        ></vue-feather>
                    </span>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="Recherche"
                        v-model="searchField"
                        v-on:keyup="searchTextChange"
                        @input="searchTextChange()"
                    />
                </div>
            </div>
            <div
                class="light-box"
                @click="showFilter()"
                v-if="filterOptions1 || filterOptions2 || filterOptions3"
            >
                <a
                    data-bs-toggle="collapse"
                    href="#collapseFilter"
                    role="button"
                    :aria-expanded="show ? true : false"
                    aria-controls="collapseFilter"
                    ><vue-feather
                        class="filter-icon"
                        :class="show ? 'hide' : 'show'"
                        type="filter"
                    ></vue-feather
                    ><i
                        class="icon-close filter-close"
                        :class="show ? 'show' : 'hide'"
                    ></i
                ></a>
            </div>
            <router-link
                v-if="props.addLink"
                :to="props.addLink"
                class="btn btn-primary"
                ><i class="fa-solid fa-plus"></i>{{ buttonTitle }}</router-link
            >
        </div>
        <div :class="show ? 'show' : 'd-none'" id="collapseFilter">
            <div class="list-filter-body">
                <div class="row g-3">
                    <div class="col" v-if="filterOptions1">
                        <select
                            class="form-select"
                            id="edgesselect"
                            v-model="selectedOption1"
                        >
                            <option
                                v-for="option in filterOptions1"
                                :key="option.key"
                                :value="option.key"
                                @click="changeFilter()"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col" v-if="filterOptions2">
                        <select
                            class="form-select"
                            id="edgesselect"
                            v-model="selectedOption2"
                        >
                            <option
                                v-for="option in filterOptions1"
                                :key="option.key"
                                :value="option.key"
                                @click="changeFilter()"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col" v-if="filterOptions3">
                        <select
                            class="form-select"
                            id="edgesselect"
                            v-model="selectedOption3"
                        >
                            <option
                                v-for="option in filterOptions1"
                                :key="option.key"
                                :value="option.key"
                                @click="changeFilter()"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import type { searchOption, searchRequest } from '@/core/data/searchOption';
import { onMounted, ref } from 'vue';
let selectedOption1 = ref<searchOption>();
let selectedOption2 = ref<searchOption>();
let selectedOption3 = ref<searchOption>();
let searchField = ref<string>('');
let selectedOption = ref<searchOption>();
let props = defineProps({
    //@ts-ignore
    filterOptions1: Array as PropType<searchOption[]>,
    //@ts-ignore
    filterOptions2: Array as PropType<searchOption[]>,
    //@ts-ignore
    filterOptions3: Array as PropType<searchOption[]>,
    buttonTitle: String,
    addLink: String,
    //@ts-ignore
    searchOptions: Array as PropType<searchOption[]>,
    totalItems: Number,
    rowsPerPage: Number,
});
let show = ref<boolean>(false);
const emit = defineEmits(['filter', 'search']);
function showFilter() {
    show.value = !show.value;
}
function changeFilter() {
    const filterRequests: String[] = [];
    filterRequests.push(selectedOption1.value.key);
    filterRequests.push(selectedOption2.value.key);
    filterRequests.push(selectedOption3.value.key);

    emit('filter', filterRequests);
}
function changeSearch(search: searchOption) {
    const searchRequest: searchRequest = {
        text: searchField.value,
        key: selectedOption.value.key,
    };
    emit('search', searchRequest);
}
function searchTextChange() {
    const searchRequest: searchRequest = {
        text: searchField.value,
        //@ts-ignore
        key: selectedOption.value,
    };
    emit('search', searchRequest);
}
onMounted(() => {
    if (props.searchOptions) {
        selectedOption.value = props.searchOptions[0].key;
    }
});
</script> 