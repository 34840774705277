<template>
    <Breadcrumbs main="Utilisateur" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <UserEdit :user="user" />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { tm, rt } = useI18n();
import config from '@/config';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { user } from '@/core/data/user';
const UserEdit = defineAsyncComponent(
    () => import('@/components/theme/user/UserEdit.vue'),
);
let towns = ref([]);
let user = ref<user>({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    role: config.role.user,
    townId: '',
    avatar: undefined,
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    status: '',
});

const title = computed(() => {
    return user.value && user.value.email
        ? rt(tm('userEdit.updatetitle'))
        : rt(tm('userEdit.addtitle'));
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.email !== undefined) {
        //@ts-ignore
        ApiServices.update('users', user.value, user.value._id)
            .then(() => {
                success_message('Utilisateur modifié.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('users', user.value)
            .then(() => {
                success_message('Utilisateur créé.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.email !== undefined) {
        ApiServices.getById('users', route.query.email.toString()).then(
            (response: any) => {
                user.value = response;
            },
        );
    }
    ApiServices.getAll('town', { status_eq: 'ACTIVE' }, ['name'], null).then(
        (response: any) => {
            towns.value = response.entries;
        },
    );
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>