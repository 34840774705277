<template>
    <Breadcrumbs
        main="Liste des signalements"
        mains="Liste des signalements"
        title="Signalement"
    />
    <div class="container-fluid">
        <div class="row">
            <ReportList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const ReportList = defineAsyncComponent(
    () => import('@/components/theme/report/ReportList.vue'),
);
</script>