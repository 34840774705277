export function authHeader() {
    // return authorization header with jwt token
    let local_user = localStorage.getItem('user')
    if(local_user) {
        let user = JSON.parse(local_user);
        if (user && user.token) {
            return user.token;
    }
    return '';
    }
}