interface MenuItem {
    headTitle1?: string;
    headTitle2?: string;
    title?: string;
    icon?: string;
    icon1?: string;
    type: string;
    badgeType?: string;
    active?: boolean;
    isPinned?: boolean;
    path?: string;
    bagde?: string;
    children?: MenuItem[];
    bookmark?: boolean;
    role: number;
}

export const menu: MenuItem[] = [
    {
        headTitle1: 'General',
        headTitle2: 'General',
        type: 'headtitle',
        role: 2,
    },
    {
        path: '/',
        title: 'menu.home',
        icon: 'Home-dashboard',
        icon1: 'fill-home',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/dashboard',
        title: 'menu.dashboard',
        icon: 'Pie',
        icon1: 'fill-widget',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/map',
        title: 'menu.map',
        icon: 'Discovery',
        icon1: 'fill-maps',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        headTitle1: 'Publication',
        headTitle2: 'Publication',
        type: 'headtitle',
        role: 2,
    },
    {
        path: '/newsList',
        title: 'menu.newsList',
        icon: 'Activity',
        icon1: 'fill-file',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/eventList',
        title: 'menu.eventList',
        icon: 'Calendar',
        icon1: 'fill-calender',
        type: 'link',
        isPinned: false,
        bookmark: true,
        role: 2,
    },
    {
        path: '/pageList',
        title: 'menu.pageList',
        icon: 'Paper',
        icon1: 'fill-file',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/cardList',
        title: 'Contacts',
        icon: 'Contacts',
        icon1: 'fill-contact',
        type: 'link',
        isPinned: false,
        bookmark: true,
        role: 2,
    },
    {
        headTitle1: 'Echanges',
        headTitle2: 'Echanges',
        type: 'headtitle',
        role: 2,
    },
    {
        path: '/reportList',
        title: 'menu.reportList',
        icon: 'Info-circle',
        icon1: 'fill-maps',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/notificationList',
        title: 'Notifications',
        icon: 'Danger',
        icon1: 'fill-contact',
        type: 'link',
        isPinned: false,
        bookmark: true,
        role: 2,
    },
    {
        path: '/surveyList',
        title: 'menu.surveyList',
        icon: 'Chat',
        icon1: 'fill-voice',
        type: 'link',
        isPinned: false,
        bookmark: true,
        role: 2,
    },
    {
        headTitle1: 'Paramètres',
        headTitle2: 'Paramètres',
        type: 'headtitle',
        role: 2,
    },
    {
        path: '/gallery',
        title: 'menu.imageGallery',
        icon: 'Gallery',
        icon1: 'fill-gallery',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/pageCategoryList',
        title: 'menu.pageCategoryList',
        icon: 'Document',
        icon1: 'fill-document',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        path: '/socialNetworkList',
        title: 'menu.socialNetworkList',
        icon: 'Camera',
        icon1: 'fill-document',
        type: 'link',
        isPinned: false,
        active: false,
        role: 2,
    },
    {
        title: 'Paramètres',
        icon: 'Setting',
        icon1: 'fill-star',
        type: 'sub',
        active: false,
        isPinned: false,
        role: 2,
        children: [
            {
                path: '/townList',
                title: 'menu.townList',
                type: 'link',
                role: 1,
            },
            {
                path: '/userList',
                title: 'menu.userList',
                type: 'link',
                role: 1,
            },
            {
                path: '/settings/edit',
                title: 'menu.generalSettings',
                type: 'link',
                role: 2,
            },
            {
                path: '/pageTypeList',
                title: 'menu.pageTypeList',
                type: 'link',
                role: 1,
            },
            {
                path: '/notificationTopicList',
                title: 'menu.notificationTopicList',
                type: 'link',
                role: 2,
            },
            {
                path: '/reportCategoryList',
                title: 'menu.reportCategoryList',
                type: 'link',
                role: 2,
            },
            {
                path: '/eventTypeList',
                title: 'menu.eventTypes',
                type: 'link',
                role: 2,
            },
        ],
    },
];
