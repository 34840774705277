<template>
    <Breadcrumbs
        main="Liste des catégories"
        mains="Liste des catégories"
        title="Paramètres"
    />
    <div class="container-fluid">
        <div class="row">
            <PageCategoryList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const PageCategoryList = defineAsyncComponent(
    () => import('@/components/theme/page/PageCategoryList.vue'),
);
</script>