<template>
    <Breadcrumbs main="Synthèse" mains="Synthèse" title="Accueil" />
    <div class="container-fluid">
        <HomeMain />
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const HomeMain = defineAsyncComponent(
    () => import('@/components/theme/home/HomeMain.vue'),
);
</script>