import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "text-end" }
const _hoisted_5 = {
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuButton1"
}

import { useRoute } from 'vue-router';
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { report } from '@/core/data/report';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportView',
  setup(__props) {

let route = useRoute();
const pictures = ref([]);
const ReportView = defineAsyncComponent(
    () => import('@/components/theme/report/ReportView.vue'),
);

let report = ref<report>(null);
const title = computed(() => {
    return report.value && report.value.id
        ? 'Traitement du signalement : ' + report.value.id
        : '';
});
const setStatus = async (status: string) => {
    useLoaderState().changeStateTrue();
    ApiServices.setStatus('reports', status, report.value.id, null)
        .then((response: any) => {
            success_message('signalement modifié.');
            getDatas();
        })
        .catch((ex) => {
            error_message(ex);
        })
        .finally(() => {
            useLoaderState().changeStateFalse();
        });
};
const generateReport = async () => {
    try {
        success_message('Rapport généré.');
        await ApiServices.generatePdf(
            'reports/pdf',
            report.value.id,
            'Rapport_',
        );
    } catch (ex) {
        error_message(ex);
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('reports', route.query.id.toString()).then(
            async (response: any) => {
                report.value = response;
                if (report.value.pictures && report.value.pictures.length > 0) {
                    pictures.value = [];
                    for (let i = 0; i < report.value.pictures.length; i++) {
                        await ApiServices.getById(
                            'GCS',
                            report.value.pictures[i],
                        ).then((response: any) => {
                            pictures.value.push(response);
                        });
                    }
                }
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Signalement",
      mains: title.value,
      title: "Accueil"
    }, null, 8, ["mains"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(ReportView), {
        report: _unref(report),
        pictures: pictures.value
      }, null, 8, ["report", "pictures"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[14] || (_cache[14] = _createElementVNode("button", {
              class: "btn dropdown-toggle btn-primary text-white me-1",
              type: "button",
              id: "dropdownMenuButton1",
              "data-bs-toggle": "dropdown",
              "aria-expanded": "false"
            }, " Changer l'état ", -1)),
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (setStatus('TRANSMITED')))
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fa fa-paper-plane" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('TRANSMITED')), 1)
                ])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (setStatus('PLANIFIED')))
                }, [
                  _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fa fa-eye" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('PLANIFIED')), 1)
                ])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (setStatus('INPROGRESS')))
                }, [
                  _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fa fa-gear" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('INPROGRESS')), 1)
                ])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item text-success",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (setStatus('FINISHED')))
                }, [
                  _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fa fa-flag" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('FINISHED')), 1)
                ])
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("li", null, [
                _createElementVNode("hr", { class: "dropdown-divider" })
              ], -1)),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item text-warning",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (setStatus('PENDING')))
                }, [
                  _cache[11] || (_cache[11] = _createElementVNode("i", { class: "fa fa-pause" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('PENDING')), 1)
                ])
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  class: "dropdown-item text-danger",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (setStatus('DELETED')))
                }, [
                  _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fa fa-trash" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETED')), 1)
                ])
              ])
            ]),
            _createElementVNode("a", {
              class: "btn btn-info",
              target: "_blank",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (generateReport()))
            }, _toDisplayString(_ctx.$t('pageReportEdit.pdf')), 1)
          ])
        ])
      ])
    ])
  ], 64))
}
}

})