<template>
    <Breadcrumbs main="Catégorie de page" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <PageCategoryEdit :pageCategory="pageCategory" />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import config from '@/config';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { pageCategory } from '@/core/data/pageCategory';
const PageCategoryEdit = defineAsyncComponent(
    () => import('@/components/theme/page/PageCategoryEdit.vue'),
);
let pageCategory = ref<pageCategory>({
    id: '',
    title: '',
    parentCategoryId: null,
    createdAt: new Date(),
    createdBy: undefined,
    updatedAt: new Date(),
    updatedBy: undefined,
    status: 'CREATED',
});

const title = computed(() => {
    return pageCategory.value && pageCategory.value.id
        ? "Modification d'une catégorie"
        : "Création d'une catégorie de page";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.id !== undefined) {
        //@ts-ignore
        ApiServices.update(
            'pageCategories',
            pageCategory.value,
            pageCategory.value.id,
        )
            .then(() => {
                success_message('Catégorie modifiée.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('pageCategories', pageCategory.value)
            .then(() => {
                success_message('Catégorie créee.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('pageCategories', route.query.id.toString()).then(
            (response: any) => {
                pageCategory.value = response;
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>