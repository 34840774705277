import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  class: "simple-wrapper nav nav-tabs",
  id: "surveyEditTab",
  role: "tablist"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = {
  class: "nav-link active text-primary",
  id: "home-tab",
  "data-bs-toggle": "tab",
  href: "#home",
  role: "tab",
  "aria-controls": "home",
  "aria-selected": "true"
}
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = {
  class: "nav-link text-primary",
  id: "questions-tabs",
  "data-bs-toggle": "tab",
  href: "#questions",
  role: "tab",
  "aria-controls": "questions",
  "aria-selected": "false"
}
const _hoisted_8 = {
  class: "tab-content",
  id: "surveyEditTabContent"
}
const _hoisted_9 = {
  class: "tab-pane fade show active mt-4",
  id: "home",
  role: "tabpanel",
  "aria-labelledby": "home-tab"
}
const _hoisted_10 = {
  class: "tab-pane fade",
  id: "questions",
  role: "tabpanel",
  "aria-labelledby": "questions-tabs"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "text-end" }

import { useRoute } from 'vue-router';
import config from '@/config';
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { survey } from '@/core/data/survey';

export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyEdit',
  setup(__props) {

let route = useRoute();
const SurveyGeneralEdit = defineAsyncComponent(
    () => import('@/components/theme/survey/SurveyGeneralEdit.vue'),
);
const SurveyQuestionsEdit = defineAsyncComponent(
    () => import('@/components/theme/survey/SurveyQuestionsEdit.vue'),
);
let survey = ref<survey>({
    id: '',
    title: undefined,
    startImageUrl: undefined,
    endImageUrl: undefined,
    longDescription: undefined,
    endMessage: undefined,
    timings: [
        {
            startDate: new Date(),
            endDate: new Date(new Date().setDate(new Date().getDate() + 31)),
        },
    ],
    questions: [],
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    status: 'ACTIVE',
});

const title = computed(() => {
    return survey.value && survey.value.id
        ? "Modification d'un sondage"
        : "Création d'un sondage";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.id !== undefined) {
        //@ts-ignore
        ApiServices.update('surveys', survey.value, survey.value.id)
            .then(() => {
                success_message('Sondage modifié.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('surveys', survey.value)
            .then(() => {
                success_message('Sondage Crée.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('surveys', route.query.id.toString()).then(
            (response: any) => {
                survey.value = response;
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card3 = _resolveComponent("Card3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Ville",
      mains: title.value,
      title: "Accueil"
    }, null, 8, ["mains"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card3, { colClass: "col-sm-12" }, {
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_3, [
              _createElementVNode("li", _hoisted_4, [
                _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('pageEdit.tabGeneral')), 1)
              ]),
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('surveyEdit.tabQuestions')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(SurveyGeneralEdit), { survey: _unref(survey) }, null, 8, ["survey"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(SurveyQuestionsEdit), { survey: _unref(survey) }, null, 8, ["survey"])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("a", {
                    class: "btn btn-success me-1",
                    target: "_blank",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (save()))
                  }, _toDisplayString(_ctx.$t('save')), 1)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})