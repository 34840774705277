import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "text-end" }

import { useRoute } from 'vue-router';
import config from '@/config';
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { pageCategory } from '@/core/data/pageCategory';

export default /*@__PURE__*/_defineComponent({
  __name: 'PageCategoryEdit',
  setup(__props) {

let route = useRoute();
const PageCategoryEdit = defineAsyncComponent(
    () => import('@/components/theme/page/PageCategoryEdit.vue'),
);
let pageCategory = ref<pageCategory>({
    id: '',
    title: '',
    parentCategoryId: null,
    createdAt: new Date(),
    createdBy: undefined,
    updatedAt: new Date(),
    updatedBy: undefined,
    status: 'CREATED',
});

const title = computed(() => {
    return pageCategory.value && pageCategory.value.id
        ? "Modification d'une catégorie"
        : "Création d'une catégorie de page";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.id !== undefined) {
        //@ts-ignore
        ApiServices.update(
            'pageCategories',
            pageCategory.value,
            pageCategory.value.id,
        )
            .then(() => {
                success_message('Catégorie modifiée.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('pageCategories', pageCategory.value)
            .then(() => {
                success_message('Catégorie créee.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('pageCategories', route.query.id.toString()).then(
            (response: any) => {
                pageCategory.value = response;
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card3 = _resolveComponent("Card3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Catégorie de page",
      mains: title.value,
      title: "Accueil"
    }, null, 8, ["mains"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card3, { colClass: "col-sm-12" }, {
          default: _withCtx(() => [
            _createVNode(_unref(PageCategoryEdit), { pageCategory: _unref(pageCategory) }, null, 8, ["pageCategory"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("a", {
                    class: "btn btn-success me-1",
                    target: "_blank",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (save()))
                  }, _toDisplayString(_ctx.$t('save')), 1)
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})