<template>
    <Breadcrumbs main="Evénements" mains="Evénements" title="Publication" />
    <div class="container-fluid">
        <div class="row">
            <EventList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';
const EventList = defineAsyncComponent(
    () => import('@/components/theme/event/EventList.vue'),
);
</script>