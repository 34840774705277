import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AuthGuard from './authGuard';
import { useLoaderState } from '@/store/loading';
import Config from '@/config';
import BodyView from '@/layouts/BodyView.vue';
import IndexError400 from '@/pages/error/Error400.vue';
import IndexErrorPage2 from '@/pages/error/IndexErrorPage2.vue';
import IndexErrorPage3 from '@/pages/error/IndexErrorPage3.vue';
import IndexErrorPage4 from '@/pages/error/IndexErrorPage4.vue';
import IndexErrorPage5 from '@/pages/error/IndexErrorPage5.vue';
import IndexErrorPage6 from '@/pages/error/IndexErrorPage6.vue';
import DashBoardPage from '@/pages/dashboard/DashBoardPage.vue';
import HomePage from '@/pages/HomePage.vue';
import Login from '@/pages/authentication/Login.vue';
import Logout from '@/pages/authentication/Logout.vue';
import Register from '@/pages/authentication/Register.vue';
import ForgetPassword from '@/pages/authentication/ForgetPassword.vue';
import PageList from '@/pages/page/PageList.vue';
import PageEdit from '@/pages/page/PageEdit.vue';
import NewsList from '@/pages/news/NewsList.vue';
import NewsEdit from '@/pages/news/NewsEdit.vue';
import CardList from '@/pages/card/CardList.vue';
import CardEdit from '@/pages/card/CardEdit.vue';
import PageTypeList from '@/pages/page/PageTypeList.vue';
import NotificationList from '@/pages/notification/NotificationList.vue';
import NotificationEdit from '@/pages/notification/NotificationEdit.vue';
import NotificationTopicList from '@/pages/notification/NotificationTopicList.vue';
import NotificationTopicEdit from '@/pages/notification/NotificationTopicEdit.vue';
import TownList from '@/pages/town/TownList.vue';
import TownEdit from '@/pages/town/TownEdit.vue';
import SettingsEdit from '@/pages/settings/SettingsEdit.vue';
import UserList from '@/pages/user/UserList.vue';
import UserEdit from '@/pages/user/UserEdit.vue';
import EventList from '@/pages/event/EventList.vue';
import InteractiveMap from '@/pages/map/InteractiveMap.vue';
import ImageGallery from '@/pages/gallery/ImageGallery.vue';
import ReportList from '@/pages/report/ReportList.vue';
import ReportView from '@/pages/report/ReportView.vue';
import SurveyList from '@/pages/survey/SurveyList.vue';
import SurveyEdit from '@/pages/survey/SurveyEdit.vue';
import SurveyResults from '@/pages/survey/SurveyResults.vue';
import ReportCategoryList from '@/pages/report/ReportCategoryList.vue';
import ReportCategoryEdit from '@/pages/report/ReportCategoryEdit.vue';
import EventTypeList from '@/pages/event/EventTypeList.vue';
import EventTypeEdit from '@/pages/event/EventTypeEdit.vue';
import PageCategoryList from '@/pages/page/PageCategoryList.vue';
import PageCategoryEdit from '@/pages/page/PageCategoryEdit.vue';
import SocialNetwokList from '@/pages/socialNetwork/SocialNetworkList.vue';
import SocialNetwokEdit from '@/pages/socialNetwork/SocialNetworkEdit.vue';
import AccountEdit from '@/pages/account/AccountEdit.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        name: 'home',
        component: BodyView,
        meta: {
            title: 'Mytown Portal',
        },
        children: [
            {
                path: '',
                name: 'home',
                component: HomePage,
                meta: {
                    title: 'Mytown Portal - Home',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'dashboard',
                name: 'defaultRoot',
                component: DashBoardPage,
                meta: {
                    title: 'Mytown Portal - Dashbord',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'pageList',
                name: 'pageList',
                component: PageList,
                meta: {
                    title: 'Mytown Portal - Page List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'account',
                name: 'account',
                component: AccountEdit,
                meta: {
                    title: 'Mytown Portal - Account Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'cardList',
                name: 'cardList',
                component: CardList,
                meta: {
                    title: 'Mytown Portal - Card List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'card/edit',
                name: 'cardEdit',
                component: CardEdit,
                meta: {
                    title: 'Mytown Portal - Card Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'newsList',
                name: 'newsList',
                component: NewsList,
                meta: {
                    title: 'Mytown Portal - News List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'news/edit',
                name: 'newsEdit',
                component: NewsEdit,
                meta: {
                    title: 'Mytown Portal - News Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'pageTypeList',
                name: 'pageTypeList',
                component: PageTypeList,
                meta: {
                    title: 'Mytown Portal - Page type List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin],
                },
            },
            {
                path: 'page/edit',
                name: 'pageEdit',
                component: PageEdit,
                meta: {
                    title: 'Mytown Portal - Page Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'notificationList',
                name: 'notificationList',
                component: NotificationList,
                meta: {
                    title: 'Mytown Portal - Notification List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'notification/edit',
                name: 'notificationEdit',
                component: NotificationEdit,
                meta: {
                    title: 'Mytown Portal - Notification Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'notificationTopicList',
                name: 'notificationTopicList',
                component: NotificationTopicList,
                meta: {
                    title: 'Mytown Portal - Notification Topic List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'notificationTopic/edit',
                name: 'notificationTopicEdit',
                component: NotificationTopicEdit,
                meta: {
                    title: 'Mytown Portal - Notification Topic Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'townList',
                name: 'townList',
                component: TownList,
                meta: {
                    title: 'Mytown Portal - Town List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin],
                },
            },
            {
                path: 'town/edit',
                name: 'townEdit',
                component: TownEdit,
                meta: {
                    title: 'Mytown Portal - Town Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin],
                },
            },
            {
                path: 'settings/edit',
                name: 'settingsEdit',
                component: SettingsEdit,
                meta: {
                    title: 'Mytown Portal - Settings Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'userList',
                name: 'userList',
                component: UserList,
                meta: {
                    title: 'Mytown Portal - User List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'user/edit',
                name: 'userEdit',
                component: UserEdit,
                meta: {
                    title: 'Mytown Portal - User Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin],
                },
            },
            {
                path: 'eventList',
                name: 'eventList',
                component: EventList,
                meta: {
                    title: 'Mytown Portal - Event List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'map',
                name: 'map',
                component: InteractiveMap,
                meta: {
                    title: 'Mytown Portal - InteractiveMap',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'gallery',
                name: 'gallery',
                component: ImageGallery,
                meta: {
                    title: 'Mytown Portal - Image Gallery',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'reportList',
                name: 'reportList',
                component: ReportList,
                meta: {
                    title: 'Mytown Portal - Report  List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'reportView',
                name: 'reportView',
                component: ReportView,
                meta: {
                    title: 'Mytown Portal - Report  View',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'reportCategoryList',
                name: 'reportCategoryList',
                component: ReportCategoryList,
                meta: {
                    title: 'Mytown Portal - Report Category List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'reportCategory/edit',
                name: 'reportCategoryEdit',
                component: ReportCategoryEdit,
                meta: {
                    title: 'Mytown Portal - Report Category Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'surveyList',
                name: 'surveyList',
                component: SurveyList,
                meta: {
                    title: 'Mytown Portal - Survey List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'survey/edit',
                name: 'surveyEdit',
                component: SurveyEdit,
                meta: {
                    title: 'Mytown Portal - Survey Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'survey/results',
                name: 'surveyResults',
                component: SurveyResults,
                meta: {
                    title: 'Mytown Portal - Survey Results',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'eventTypeList',
                name: 'eventTypeList',
                component: EventTypeList,
                meta: {
                    title: 'Mytown Portal - Event Type List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'eventType/edit',
                name: 'eventTypeEdit',
                component: EventTypeEdit,
                meta: {
                    title: 'Mytown Portal - Event Type Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin],
                },
            },
            {
                path: 'pageCategoryList',
                name: 'pageCategoryList',
                component: PageCategoryList,
                meta: {
                    title: 'Mytown Portal - Page Category List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'pageCategory/edit',
                name: 'pageCategoryEdit',
                component: PageCategoryEdit,
                meta: {
                    title: 'Mytown Portal - Page Category Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'socialNetworkList',
                name: 'socialNetworkList',
                component: SocialNetwokList,
                meta: {
                    title: 'Mytown Portal - Social Network List',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
            {
                path: 'socialNetwork/edit',
                name: 'socialNetworkEdit',
                component: SocialNetwokEdit,
                meta: {
                    title: 'Mytown Portal - Social Network Edit',
                    loginRequired: true,
                    roles: [Config.role.superAdmin, Config.role.admin],
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: 'Register',
        },
    },
    {
        path: '/forget_password',
        name: 'forgetPassword',
        component: ForgetPassword,
        meta: {
            title: 'Forget Password',
        },
    },
    {
        path: '/error-400',
        name: 'error400',
        component: IndexError400,
        meta: {
            title: 'Error 400 | Bad Request',
        },
    },
    {
        path: '/error_page2',
        name: 'errorPage2',
        component: IndexErrorPage2,
        meta: {
            title: 'Error Page2 | Admiro - Premium Vue Admin Template',
        },
    },
    {
        path: '/error_page3',
        name: 'errorPage3',
        component: IndexErrorPage3,
        meta: {
            title: 'Error Page3 | Admiro - Premium Vue Admin Template',
        },
    },
    {
        path: '/error_page4',
        name: 'errorPage4',
        component: IndexErrorPage4,
        meta: {
            title: 'Error Page4 | Admiro - Premium Vue Admin Template',
        },
    },
    {
        path: '/error_page5',
        name: 'errorPage5',
        component: IndexErrorPage5,
        meta: {
            title: 'Error Page5 | Admiro - Premium Vue Admin Template',
        },
    },
    {
        path: '/error_page6',
        name: 'errorPage6',
        component: IndexErrorPage6,
        meta: {
            title: 'Error Page6 | Admiro - Premium Vue Admin Template',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// security
router.beforeEach(AuthGuard);
router.beforeEach((to, from, next) => {
    const isLoading = useLoaderState();
    const { changeStateTrue } = isLoading;
    changeStateTrue();
    if (typeof to.meta.title === 'string') {
        document.title = to.meta.title;
    }
    setTimeout(() => {
        next();
    }, 500);
});
router.afterEach((to, from) => {
    const isLoading = useLoaderState();
    const { changeStateFalse } = isLoading;
    changeStateFalse();
});

export default router;
