<template>
    <Breadcrumbs
        main="Tableau de bord"
        mains="Tableau de bord"
        title="Tableau de bord"
    />
    <div class="container-fluid">
        <div class="row starter-main">
            <DashBoardMain />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const DashBoardMain = defineAsyncComponent(
    () => import('@/components/theme/dashboard/DashBoardMain.vue'),
);
</script>