import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card login-dark" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "col-form-label" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "col-form-label" }
const _hoisted_13 = { class: "form-input position-relative" }
const _hoisted_14 = ["type"]
const _hoisted_15 = { class: "show-hide" }
const _hoisted_16 = { class: "form-group mb-0 checkbox-checked" }
const _hoisted_17 = { class: "text-end mt-3" }
const _hoisted_18 = {
  class: "btn btn-primary btn-block w-100",
  type: "submit"
}
const _hoisted_19 = { class: "login-social-title" }
const _hoisted_20 = { class: "mt-4 mb-0 text-center" }

import { ref } from 'vue'
import { useAuthStore } from '@/store/auth'

export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const email = ref<string>('')
const password = ref<string>('')
let message = ref(null)
const type = ref<string>('password')
function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

function login() {
    try {
        const authStore = useAuthStore()
        authStore.login({email:email.value, password:password.value}).catch((error) => (message.value = error.message))
    } finally {
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "theme-form",
                onSubmit: _withModifiers(login, ["prevent"])
              }, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('login.title')), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('login.subTitle')), 1),
                (_unref(message))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(message)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('login.login')), 1),
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                    type: "email",
                    required: "",
                    placeholder: "test@gmail.com"
                  }, null, 512), [
                    [_vModelText, email.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('login.password')), 1),
                  _createElementVNode("div", _hoisted_13, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      type: type.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                      name: "login[password]",
                      required: "",
                      placeholder: "*********"
                    }, null, 8, _hoisted_14), [
                      [_vModelDynamic, password.value]
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", {
                        class: _normalizeClass(type.value == 'password' ? 'show' : ''),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "form-check checkbox-solid-info" }, null, -1)),
                  _createVNode(_component_router_link, {
                    class: "link",
                    to: "/forget_password"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('login.forgotPassword')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$t('user.login-button')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("h6", null, _toDisplayString(_ctx.$t('login.orSign')), 1)
                ]),
                _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"form-group\"><ul class=\"login-social\"><li><a href=\"https://www.linkedin.com\" target=\"_blank\"><i class=\"icon-linkedin\"></i></a></li><li><a href=\"https://twitter.com\" target=\"_blank\"><i class=\"icon-twitter\"></i></a></li><li><a href=\"https://www.facebook.com\" target=\"_blank\"><i class=\"icon-facebook\"></i></a></li><li><a href=\"https://www.instagram.com\" target=\"_blank\"><i class=\"icon-instagram\"></i></a></li></ul></div>", 1)),
                _createElementVNode("p", _hoisted_20, [
                  _createTextVNode(_toDisplayString(_ctx.$t('login.notRegister')), 1),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/register"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('login.register')), 1)
                    ]),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})