<template>
    <Breadcrumbs
        main="Liste des actualités"
        mains="Liste des actualités"
        title="Publication"
    />
    <div class="container-fluid">
        <div class="row">
            <NewsList />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';
const NewsList = defineAsyncComponent(
    () => import('@/components/theme/news/NewsList.vue'),
);
</script>