<template>
    <Breadcrumbs main="Type d'événement" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <EventTypeEdit :eventType="eventType" />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import config from '@/config';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { eventType } from '@/core/data/eventType';
const EventTypeEdit = defineAsyncComponent(
    () => import('@/components/theme/event/EventTypeEdit.vue'),
);
let eventType = ref<eventType>({
    id: '',
    title: '',
    entity: 'EVENT',
});

const title = computed(() => {
    return eventType.value && eventType.value.id
        ? "Modification d'un type d'événement"
        : "Création d'un type d'événement";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.id !== undefined) {
        //@ts-ignore
        ApiServices.update('types', eventType.value, eventType.value.id)
            .then(() => {
                success_message("Type d'événement modifié.");
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('types', eventType.value)
            .then(() => {
                success_message("Type d'événement crée.");
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('types', route.query.id.toString()).then(
            (response: any) => {
                eventType.value = response;
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>