<template>
    <div class="page-wrapper">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="login-card login-dark">
                        <div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <h2>
                                        {{ $t('forgottenPassword.title') }}
                                    </h2>
                                    <p class="text-center">
                                        {{ $t('forgottenPassword.subTitle') }}
                                    </p>

                                    <div class="form-group">
                                        <label class="col-form-label">{{
                                            $t('register.email')
                                        }}</label>
                                        <input
                                            class="form-control"
                                            type="email"
                                            v-model="email"
                                            required
                                            placeholder="Test@gmail.com"
                                        />
                                    </div>
                                    <div
                                        class="form-group mb-0 checkbox-checked"
                                    >
                                        <button
                                            class="btn btn-primary btn-block w-100 mt-3"
                                            type="submit"
                                        >
                                            {{
                                                $t('user.reset-password-button')
                                            }}
                                        </button>
                                    </div>
                                    <p class="mt-4 mb-0 text-center">
                                        {{ $t('register.please-please-login')
                                        }}<router-link
                                            class="ms-2"
                                            to="/login"
                                            >{{
                                                $t('register.login')
                                            }}</router-link
                                        >
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { success_message, error_message } from '@/components/common/toast';
let message = ref(null);
const email = ref<string>('');
function register() {
    try {
        if (email.value === '') {
            const authStore = useAuthStore();
            authStore
                .sendPasswordEmail(email.value)
                .catch((error) => (message.value = error.message));
            success_message('email envoyé');
        } else {
            message.value = "Vous remplir l'email.";
        }
    } catch (ex) {
        message.value = ex.message;
        error_message(ex.message);
    } finally {
    }
}
</script>