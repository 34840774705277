<template>
    <div class="error-wrapper">
        <div class="container">
            <ErrorPage400 />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineAsyncComponent } from 'vue';
const ErrorPage400 = defineAsyncComponent(
    () => import('@/components/theme/error/Error400.vue'),
);
</script>