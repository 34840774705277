<template>
    <Breadcrumbs main="Paramètres" mains="Paramètres" title="Accueil" />
    <div class="container-fluid">
        <div class="edit-profile">
            <div class="row">
                <EditGeneralSettings :settings="generalSettings" />
                <EditHomeSettings :settings="generalSettings" />
                <EditReportSettings :settings="generalSettings" />
                <EditAccountSettings :settings="generalSettings" />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { appsettings } from '@/core/data/appsettings';
import { error_message, success_message } from '@/components/common/toast';
import config from '@/config';
const EditGeneralSettings = defineAsyncComponent(
    () => import('@/components/theme/settings/EditGeneralSettings.vue'),
);
const EditHomeSettings = defineAsyncComponent(
    () => import('@/components/theme/settings/EditHomeSettings.vue'),
);
const EditReportSettings = defineAsyncComponent(
    () => import('@/components/theme/settings/EditReportSettings.vue'),
);
const EditAccountSettings = defineAsyncComponent(
    () => import('@/components/theme/settings/EditAccountSettings.vue'),
);
const me = ref({});
const townId = ref('');
const townSettings = ref([]);
const oldTownSettings = ref([]);
let generalSettings = ref<appsettings>({
    welcomeTitle: '',
    homeMessageEnabled: false,
    ndDaysAfterNewsEnabled: false,
    reportEmailNotificationEnabled: false,
    ndDaysAfterNews: 100,
    homeMessage: '',
    homeMessageType: 'NONE',
    reportReceiveEmail: '',
    accountSettingsFireBase: '',
});
const isSuperAdmin = computed(() => {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (
        user !== null &&
        user.token !== null &&
        user.user.role !== null &&
        user.user.role === config.role.superAdmin
    ) {
        return true;
    }
    return false;
});
const save = async () => {
    useLoaderState().changeStateTrue();
    try {
        if (townId.value !== undefined && townId.value !== '') {
            if (isSuperAdmin) {
                await saveValue(
                    'TOWN_SETTINGS_FIREBASE_SERVICEACCOUNT',
                    generalSettings.value.accountSettingsFireBase,
                    'JSON',
                );
            }
            // welcome message
            await saveValue(
                'TOWN_SETTINGS_WELCOMEMESSAGE',
                generalSettings.value.welcomeTitle,
                'TEXT',
            );
            // delay for delete NEWS
            await saveValue(
                'TOWN_SETTINGS_NBDAYSAFTERNEWSENABLED',
                generalSettings.value.ndDaysAfterNewsEnabled,
                'BOOLEAN',
            );
            await saveValue(
                'TOWN_SETTINGS_NBDAYSAFTERNEWS',
                generalSettings.value.ndDaysAfterNews,
                'NUMBER',
            );
            // home Message
            await saveValue(
                'TOWN_SETTINGS_HOMEMESSAGEENABLED',
                generalSettings.value.homeMessageEnabled,
                'BOOLEAN',
            );
            await saveValue(
                'TOWN_SETTINGS_HOMEMESSAGE',
                generalSettings.value.homeMessage,
                'TEXT',
            );
            await saveValue(
                'TOWN_SETTINGS_HOMEMESSAGETYPE',
                generalSettings.value.homeMessageType,
                'TEXT',
            );
            // report
            await saveValue(
                'TOWN_SETTINGS_REPORTEMAILNOTIFICATIONENABLED',
                generalSettings.value.reportEmailNotificationEnabled,
                'BOOLEAN',
            );
            await saveValue(
                'TOWN_SETTINGS_REPORTRECEIVEDEMAIL',
                generalSettings.value.reportReceiveEmail,
                'TEXT',
            );
            success_message('Modification(s) effectuée(s)');
        }
    } catch (err) {
        error_message(err.message);
    }

    useLoaderState().changeStateFalse();
};

const getValue = (key: string) => {
    if (townSettings.value) {
        var theValue = townSettings.value.filter(function (elem) {
            if (elem.key === key) return elem;
        });
        if (theValue != null && theValue.length > 0) {
            return theValue[0].value;
        }
    }
    return null;
};
const saveValue = async (key: string, newValue: any, type: string) => {
    const setting = {
        key: key,
        value: newValue,
        type: type,
        townId: townId.value,
    };
    // get Old Value
    var oldValue = oldTownSettings.value.filter(function (elem) {
        if (elem.key === key) return elem;
    });
    if (oldValue !== null && oldValue.length > 0) {
        if (oldValue[0].value !== newValue) {
            // value changed and save new value (unique please)
            await ApiServices.create('townSetting', setting);
        }
    } else {
        // save new value
        await ApiServices.create('townSetting', setting);
    }
};
const getDatas = async () => {
    ApiServices.get('users/me', {}).then((response: any) => {
        me.value = response;
        if (route.query.id !== undefined) {
            //@ts-ignore
            townId.value = route.query.id;
        } else {
            //@ts-ignore
            townId.value = me.value.townId;
        }
        let filters = {
            townId_eq: townId.value,
        };
        let fields = ['key', 'value', 'type'];
        ApiServices.getAll('townSetting', filters, fields, null).then(
            (response: any) => {
                townSettings.value = response.entries;
                oldTownSettings.value = response.entries;
                if (townSettings.value !== undefined) {
                    generalSettings.value.welcomeTitle = getValue(
                        'TOWN_SETTINGS_WELCOMEMESSAGE',
                    );
                    if (
                        getValue('TOWN_SETTINGS_HOMEMESSAGEENABLED') === 'true'
                    ) {
                        generalSettings.value.homeMessageEnabled = true;
                    } else {
                        generalSettings.value.homeMessageEnabled = false;
                    }
                    if (
                        getValue('TOWN_SETTINGS_NBDAYSAFTERNEWSENABLED') ===
                        'true'
                    ) {
                        generalSettings.value.ndDaysAfterNewsEnabled = true;
                    } else {
                        generalSettings.value.ndDaysAfterNewsEnabled = false;
                    }

                    generalSettings.value.ndDaysAfterNews = getValue(
                        'TOWN_SETTINGS_NBDAYSAFTERNEWS',
                    );
                    generalSettings.value.homeMessage = getValue(
                        'TOWN_SETTINGS_HOMEMESSAGE',
                    );
                    generalSettings.value.homeMessageType = getValue(
                        'TOWN_SETTINGS_HOMEMESSAGETYPE',
                    );
                    generalSettings.value.reportEmailNotificationEnabled =
                        getValue(
                            'TOWN_SETTINGS_REPORTEMAILNOTIFICATIONENABLED',
                        );
                    generalSettings.value.reportReceiveEmail = getValue(
                        'TOWN_SETTINGS_REPORTRECEIVEDEMAIL',
                    );
                    generalSettings.value.accountSettingsFireBase = getValue(
                        'TOWN_SETTINGS_FIREBASE_SERVICEACCOUNT',
                    );
                }
            },
        );
    });
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>