import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-wrapper" }
const _hoisted_2 = { class: "container" }

import { ref, defineAsyncComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexErrorPage5',
  setup(__props) {

const ErrorPageFive = defineAsyncComponent(() => import("@/components/theme/error/ErrorPageFive.vue"))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(ErrorPageFive))
    ])
  ]))
}
}

})