import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wrapper" }
const _hoisted_2 = { class: "container-fluid p-0" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "login-card login-dark" }
const _hoisted_6 = { class: "login-main" }
const _hoisted_7 = { class: "theme-form" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "col-form-label" }
const _hoisted_11 = { class: "form-group mb-0 checkbox-checked" }
const _hoisted_12 = {
  class: "btn btn-primary btn-block w-100 mt-3",
  type: "submit"
}
const _hoisted_13 = { class: "mt-4 mb-0 text-center" }

import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { success_message, error_message } from '@/components/common/toast';

export default /*@__PURE__*/_defineComponent({
  __name: 'ForgetPassword',
  setup(__props) {

let message = ref(null);
const email = ref<string>('');
function register() {
    try {
        if (email.value === '') {
            const authStore = useAuthStore();
            authStore
                .sendPasswordEmail(email.value)
                .catch((error) => (message.value = error.message));
            success_message('email envoyé');
        } else {
            message.value = "Vous remplir l'email.";
        }
    } catch (ex) {
        message.value = ex.message;
        error_message(ex.message);
    } finally {
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("form", _hoisted_7, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('forgottenPassword.title')), 1),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('forgottenPassword.subTitle')), 1),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('register.email')), 1),
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      type: "email",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                      required: "",
                      placeholder: "Test@gmail.com"
                    }, null, 512), [
                      [_vModelText, email.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('user.reset-password-button')), 1)
                  ]),
                  _createElementVNode("p", _hoisted_13, [
                    _createTextVNode(_toDisplayString(_ctx.$t('register.please-please-login')), 1),
                    _createVNode(_component_router_link, {
                      class: "ms-2",
                      to: "/login"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('register.login')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})