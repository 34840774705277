import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card login-dark" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "col-form-label pt-0" }
const _hoisted_11 = { class: "row g-2" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = { class: "col-form-label" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "col-form-label" }
const _hoisted_20 = { class: "form-input position-relative" }
const _hoisted_21 = ["type"]
const _hoisted_22 = { class: "show-hide" }
const _hoisted_23 = { class: "form-group mb-0 checkbox-checked" }
const _hoisted_24 = { class: "form-check checkbox-solid-info" }
const _hoisted_25 = {
  class: "form-check-label",
  for: "solid6"
}
const _hoisted_26 = { class: "login-social-title" }
const _hoisted_27 = { class: "mt-4 mb-0 text-center" }

import { ref } from 'vue';
import { success_message, error_message } from '@/components/common/toast';
import { useAuthStore } from '@/store/auth';

export default /*@__PURE__*/_defineComponent({
  __name: 'Register',
  setup(__props) {

const type = ref<string>('password');
const firstName = ref<string>('');
const lastName = ref<string>('');
const email = ref<string>('');
const password = ref<string>('');
const accept = ref<boolean>(false);
let message = ref(null);
function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}
function register() {
    try {
        if (accept.value === true) {
            const authStore = useAuthStore();
            authStore
                .register({
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    password: password.value,
                })
                .catch((error) => (message.value = error.message));
            success_message('Inscription réussie.');
        } else {
            message.value =
                'Vous devez accepter les conditions et la politique de confidentialité.';
        }
    } catch (ex) {
        message.value = ex.message;
        error_message(ex.message);
    } finally {
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "theme-form",
                onSubmit: _withModifiers(register, ["prevent"])
              }, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('register.title')), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('register.please-register')), 1),
                (_unref(message))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(message)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('register.names')), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "text",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((firstName).value = $event)),
                        required: "",
                        placeholder: 
                                                    _ctx.$t('register.firstName')
                                                
                      }, null, 8, _hoisted_13), [
                        [_vModelText, firstName.value]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-control",
                        type: "text",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((lastName).value = $event)),
                        required: "",
                        placeholder: 
                                                    _ctx.$t('register.lastName')
                                                
                      }, null, 8, _hoisted_15), [
                        [_vModelText, lastName.value]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('register.email')), 1),
                  _withDirectives(_createElementVNode("input", {
                    class: "form-control",
                    type: "email",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((email).value = $event)),
                    required: "",
                    placeholder: "Test@gmail.com"
                  }, null, 512), [
                    [_vModelText, email.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('register.password')), 1),
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control",
                      type: type.value,
                      name: "login[password]",
                      required: "",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
                      placeholder: "*********"
                    }, null, 8, _hoisted_21), [
                      [_vModelDynamic, password.value]
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("span", {
                        class: _normalizeClass(
                                                    type.value == 'password'
                                                        ? 'show'
                                                        : ''
                                                ),
                        onClick: showPassword
                      }, null, 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      id: "solid6",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((accept).value = $event))
                    }, null, 512), [
                      [_vModelCheckbox, accept.value]
                    ]),
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('register.agree')), 1),
                    _createVNode(_component_router_link, {
                      class: "ms-3 link",
                      to: "/forget_password"
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("conditions & la politique de confidentialité")
                      ])),
                      _: 1
                    })
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("button", {
                    class: "btn btn-primary btn-block w-100 mt-3",
                    type: "submit"
                  }, " Créer mon compte ", -1))
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("h6", null, _toDisplayString(_ctx.$t('login.orSign')), 1)
                ]),
                _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"form-group\"><ul class=\"login-social\"><li><a href=\"https://www.linkedin.com\" target=\"_blank\"><i class=\"icon-linkedin\"></i></a></li><li><a href=\"https://twitter.com\" target=\"_blank\"><i class=\"icon-twitter\"></i></a></li><li><a href=\"https://www.facebook.com\" target=\"_blank\"><i class=\"icon-facebook\"></i></a></li><li><a href=\"https://www.instagram.com\" target=\"_blank\"><i class=\"icon-instagram\"></i></a></li></ul></div>", 1)),
                _createElementVNode("p", _hoisted_27, [
                  _createTextVNode(_toDisplayString(_ctx.$t('register.please-please-login')), 1),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/login"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('register.login')), 1)
                    ]),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})