import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export function success_message(message: string) {
    toast.success(message, {
        hideProgressBar: true,
        autoClose: 2000,
        position: 'top-right',
    });
}
export function info_message(message: string) {
    toast.info(message, {
        hideProgressBar: true,
        autoClose: 2000,
        position: 'top-right',
    });
}
export function warning_message(message: string) {
    toast.warning(message, {
        hideProgressBar: true,
        autoClose: 2000,
        position: 'top-right',
    });
}
export function error_message(message: string) {
    toast.error(message, {
        hideProgressBar: true,
        autoClose: 2000,
        position: 'top-right',
    });
}
