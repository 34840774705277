import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, isRef as _isRef, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "list-datatable-header" }
const _hoisted_2 = { class: "col-xs-3 col-sm-auto col-form-label f-w-500" }
const _hoisted_3 = {
  for: "table-complete-search",
  class: "col-xs-3 col-sm-auto col-form-label"
}
const _hoisted_4 = { class: "col-xs-6 col-sm-auto" }
const _hoisted_5 = ["value", "onClick"]
const _hoisted_6 = { class: "col-xs-6 col-sm-auto" }
const _hoisted_7 = { class: "input-group" }
const _hoisted_8 = { class: "input-group-text" }
const _hoisted_9 = ["aria-expanded"]
const _hoisted_10 = { class: "list-filter-body" }
const _hoisted_11 = { class: "row g-3" }
const _hoisted_12 = {
  key: 0,
  class: "col"
}
const _hoisted_13 = ["value"]
const _hoisted_14 = {
  key: 1,
  class: "col"
}
const _hoisted_15 = ["value"]
const _hoisted_16 = {
  key: 2,
  class: "col"
}
const _hoisted_17 = ["value"]

import type { searchOption, searchRequest } from '@/core/data/searchOption';
import { onMounted, ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'DataTableFilters',
  props: {
    //@ts-ignore
    filterOptions1: Array as PropType<searchOption[]>,
    //@ts-ignore
    filterOptions2: Array as PropType<searchOption[]>,
    //@ts-ignore
    filterOptions3: Array as PropType<searchOption[]>,
    buttonTitle: String,
    addLink: String,
    //@ts-ignore
    searchOptions: Array as PropType<searchOption[]>,
    totalItems: Number,
    rowsPerPage: Number,
},
  emits: ['filter', 'search'],
  setup(__props, { emit: __emit }) {

let selectedOption1 = ref<searchOption>();
let selectedOption2 = ref<searchOption>();
let selectedOption3 = ref<searchOption>();
let searchField = ref<string>('');
let selectedOption = ref<searchOption>();
let props = __props;
let show = ref<boolean>(false);
const emit = __emit;
function showFilter() {
    show.value = !show.value;
}
function changeFilter() {
    const filterRequests: String[] = [];
    filterRequests.push(selectedOption1.value.key);
    filterRequests.push(selectedOption2.value.key);
    filterRequests.push(selectedOption3.value.key);

    emit('filter', filterRequests);
}
function changeSearch(search: searchOption) {
    const searchRequest: searchRequest = {
        text: searchField.value,
        key: selectedOption.value.key,
    };
    emit('search', searchRequest);
}
function searchTextChange() {
    const searchRequest: searchRequest = {
        text: searchField.value,
        //@ts-ignore
        key: selectedOption.value,
    };
    emit('search', searchRequest);
}
onMounted(() => {
    if (props.searchOptions) {
        selectedOption.value = props.searchOptions[0].key;
    }
});

return (_ctx: any,_cache: any) => {
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(__props.totalItems) + " " + _toDisplayString(_ctx.$t('common.headerTable.items')), 1),
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('searchBy')) + ":", 1),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("select", {
          class: "form-select",
          id: "edgesselect",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedOption) ? (selectedOption).value = $event : selectedOption = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.searchOptions, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.key,
              value: option.key,
              onClick: ($event: any) => (changeSearch(option))
            }, _toDisplayString(option.label), 9, _hoisted_5))
          }), 128))
        ], 512), [
          [_vModelSelect, _unref(selectedOption)]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, [
            _createVNode(_component_vue_feather, {
              class: "search-icon text-gray",
              type: "search"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            class: "form-control",
            type: "text",
            placeholder: "Recherche",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchField) ? (searchField).value = $event : searchField = $event)),
            onKeyup: searchTextChange,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (searchTextChange()))
          }, null, 544), [
            [_vModelText, _unref(searchField)]
          ])
        ])
      ]),
      (__props.filterOptions1 || __props.filterOptions2 || __props.filterOptions3)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "light-box",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (showFilter()))
          }, [
            _createElementVNode("a", {
              "data-bs-toggle": "collapse",
              href: "#collapseFilter",
              role: "button",
              "aria-expanded": _unref(show) ? true : false,
              "aria-controls": "collapseFilter"
            }, [
              _createVNode(_component_vue_feather, {
                class: _normalizeClass(["filter-icon", _unref(show) ? 'hide' : 'show']),
                type: "filter"
              }, null, 8, ["class"]),
              _createElementVNode("i", {
                class: _normalizeClass(["icon-close filter-close", _unref(show) ? 'show' : 'hide'])
              }, null, 2)
            ], 8, _hoisted_9)
          ]))
        : _createCommentVNode("", true),
      (_unref(props).addLink)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: _unref(props).addLink,
            class: "btn btn-primary"
          }, {
            default: _withCtx(() => [
              _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fa-solid fa-plus" }, null, -1)),
              _createTextVNode(_toDisplayString(__props.buttonTitle), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(_unref(show) ? 'show' : 'd-none'),
      id: "collapseFilter"
    }, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (__props.filterOptions1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _withDirectives(_createElementVNode("select", {
                  class: "form-select",
                  id: "edgesselect",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(selectedOption1) ? (selectedOption1).value = $event : selectedOption1 = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filterOptions1, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.key,
                      value: option.key,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (changeFilter()))
                    }, _toDisplayString(option.label), 9, _hoisted_13))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _unref(selectedOption1)]
                ])
              ]))
            : _createCommentVNode("", true),
          (__props.filterOptions2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _withDirectives(_createElementVNode("select", {
                  class: "form-select",
                  id: "edgesselect",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(selectedOption2) ? (selectedOption2).value = $event : selectedOption2 = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filterOptions1, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.key,
                      value: option.key,
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (changeFilter()))
                    }, _toDisplayString(option.label), 9, _hoisted_15))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _unref(selectedOption2)]
                ])
              ]))
            : _createCommentVNode("", true),
          (__props.filterOptions3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _withDirectives(_createElementVNode("select", {
                  class: "form-select",
                  id: "edgesselect",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(selectedOption3) ? (selectedOption3).value = $event : selectedOption3 = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filterOptions1, (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: option.key,
                      value: option.key,
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (changeFilter()))
                    }, _toDisplayString(option.label), 9, _hoisted_17))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _unref(selectedOption3)]
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 2)
  ]))
}
}

})