<template>
    <Breadcrumbs main="Ville" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <ul
                    class="simple-wrapper nav nav-tabs"
                    id="surveyEditTab"
                    role="tablist"
                >
                    <li class="nav-item">
                        <a
                            class="nav-link active text-primary"
                            id="home-tab"
                            data-bs-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            >{{ $t('pageEdit.tabGeneral') }}</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link text-primary"
                            id="questions-tabs"
                            data-bs-toggle="tab"
                            href="#questions"
                            role="tab"
                            aria-controls="questions"
                            aria-selected="false"
                            >{{ $t('surveyEdit.tabQuestions') }}</a
                        >
                    </li>
                </ul>
                <div class="tab-content" id="surveyEditTabContent">
                    <div
                        class="tab-pane fade show active mt-4"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                    >
                        <div><SurveyGeneralEdit :survey="survey" /></div>
                    </div>
                    <div
                        class="tab-pane fade"
                        id="questions"
                        role="tabpanel"
                        aria-labelledby="questions-tabs"
                    >
                        <SurveyQuestionsEdit :survey="survey" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
import config from '@/config';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { survey } from '@/core/data/survey';
const SurveyGeneralEdit = defineAsyncComponent(
    () => import('@/components/theme/survey/SurveyGeneralEdit.vue'),
);
const SurveyQuestionsEdit = defineAsyncComponent(
    () => import('@/components/theme/survey/SurveyQuestionsEdit.vue'),
);
let survey = ref<survey>({
    id: '',
    title: undefined,
    startImageUrl: undefined,
    endImageUrl: undefined,
    longDescription: undefined,
    endMessage: undefined,
    timings: [
        {
            startDate: new Date(),
            endDate: new Date(new Date().setDate(new Date().getDate() + 31)),
        },
    ],
    questions: [],
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    status: 'ACTIVE',
});

const title = computed(() => {
    return survey.value && survey.value.id
        ? "Modification d'un sondage"
        : "Création d'un sondage";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.id !== undefined) {
        //@ts-ignore
        ApiServices.update('surveys', survey.value, survey.value.id)
            .then(() => {
                success_message('Sondage modifié.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('surveys', survey.value)
            .then(() => {
                success_message('Sondage Crée.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('surveys', route.query.id.toString()).then(
            (response: any) => {
                survey.value = response;
            },
        );
    }
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    useLoaderState().changeStateFalse();
});
</script>